export async function sendTg(name, tel, email) {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("tel", tel);
  formData.append("email", email);
  const response = await fetch("https://интернет-витрина.рф/botes.php", {
    body: formData,
    method: "POST",
  });
  return response.status;
}
