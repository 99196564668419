import logo from "./logo.svg";
import "./App.css";

import Landing from "./Landing";
import { Route, Routes, useLocation } from "react-router-dom";
import Slider from "./Slider";
import Amb from "./Amb";
import { useEffect } from "react";
import Thanks from "./Thanks";

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="App">
      <Routes>
        <Route path={"/"} element={<Landing></Landing>} />
        <Route path={"/ниши"} element={<Slider />} />
        <Route path={"/амбассадор"} element={<Amb />} />
        <Route path={"/спасибо"} element={<Thanks />} />
      </Routes>
    </div>
  );
}

export default App;
