import "./style.css";
import logo from "./img/logo.svg";
import tg from "./img/tg.svg";
import wt from "./img/wt.svg";
import arrow from "./img/arrow.svg";
import { useState } from "react";
import arr from "./img/arr.svg";
import tim from "./img/tim.svg";
import ph from "./img/ph.svg";
import { useEffect } from "react";
import { useRef } from "react";
import { Fade, Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import lp from "./img/lp.svg";
// import required modules
import { EffectCreative } from "swiper/modules";
import ReactInputMask from "react-input-mask";
import { sendTg } from "../Api/Api";
const arrPr = ["25", "75", "150", "250", "350", "500"];
const arrN = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
];
const puk1 = [
  [1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [1],
  [1],
  [1],
];

export default function Slider() {
  const Navigate = useNavigate();
  const [num, setNum] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(2);
  const [currentTheme, setCurrentTheme] = useState(0);
  const [pop, setPop] = useState(false);
  const [smB, setSmb] = useState("");
  const lft = useRef(null);
  const rgt = useRef(null);
  const [pops, setPops] = useState(false);
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (lft && rgt && lft.current && rgt.current) {
      lft.current.style =
        "transition:0s;opacity: 0;transform:translateX(-50px)";
      rgt.current.style = "transition:0s;opacity: 0;transform:translateX(50px)";
      setTimeout(() => {
        lft.current.style =
          "transition:0.3s;opacity: 1;transform:translateX(0)";
        rgt.current.style =
          "transition:0.3s;opacity: 1;transform:translateX(0)";
      }, 100);
    } else {
      lft.current.style =
        "transition:0s;opacity: 0;transform:translateX(-50px)";

      setTimeout(() => {
        lft.current.style =
          "transition:0.3s;opacity: 1;transform:translateX(0)";
      }, 100);
    }
  }, [currentPrice, currentTheme]);
  useEffect(() => {
    setCurrentTheme(0);
  }, [currentPrice]);
  useEffect(() => {
    if (document.body.clientWidth < 640) {
      let lis = document.getElementsByTagName("li");
      console.log(lis.length);
      for (let i = 0; i < lis.length; i++) {
        lis[i].onclick = function () {
          document
            .getElementById("sr")
            .scrollIntoView({ behavior: "smooth", block: "center" });
        };
      }
    }
  }, []);
  return (
    <div className="showSlider">
      {pops && (
        <Fade duration={"300"}>
          <div
            id="closeAreas"
            className="popUp"
            onClick={(e) => {
              e.target.id == "closeAreas" && setPops(false);
            }}
          >
            <div className="popUpBody">
              <h3>
                Ваш бизнес онлайн
                <span>легко и быстро!</span>
              </h3>
              <p>
                Оставьте свои контакты, получите консультацию и готовое решение
                в самое ближайшее время.
              </p>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Введите ваше имя*"
                id="name"
              />

              <ReactInputMask
                mask="+7(999)999 99 99"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                placeholder="Номер телефона*"
                id="tel"
              ></ReactInputMask>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <button
                onClick={() => {
                  if (name.length < 3) {
                    document.getElementById("name").style.border =
                      "1px solid red";
                  } else {
                    document.getElementById("name").style.border = "none";
                  }
                  if (tel.includes("_") || tel.length == 0) {
                    document.getElementById("tel").style.border =
                      "1px solid red";
                  } else {
                    document.getElementById("tel").style.border = "none";
                  }
                  if (
                    name.length >= 3 &&
                    tel.length > 0 &&
                    !tel.includes("_")
                  ) {
                    sendTg(name, tel, email).then((status) => {
                      Navigate("/спасибо");
                    });
                  }
                }}
              >
                Отправить
              </button>
            </div>
          </div>
        </Fade>
      )}
      {pop && (
        <div
          className="showCSlider"
          id="closed"
          onClick={(e) => {
            e.target.id == "closed" && setPop(false);
          }}
        >
          <div className="closeShow" onClick={() => setPop(false)}>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.0001 10.5858-6.36396-6.364-1.41422 1.41422 6.36398 6.36398-6.36398 6.364 1.41422 1.4142 6.36396-6.364 6.364 6.364 1.4142-1.4142-6.364-6.364 6.364-6.36398-1.4142-1.41422z"
                fill="rgb(255, 255, 255)"
              ></path>
            </svg>
          </div>
          <div className="showcsliderBody">
            <img
              style={
                smB == "m"
                  ? {
                      maxWidth:
                        document.body.clientWidth > 640 ? "20vw" : "92vw",
                    }
                  : {}
              }
              src={`/image/${arrPr[currentPrice]}/${
                arrN[currentTheme] + smB
              }.webp`}
              alt=""
            />
          </div>
        </div>
      )}

      <Fade duration={700} top>
        <div className="showSliderHeader">
          <img
            src={logo}
            className="showLogo"
            style={{ cursor: "pointer" }}
            onClick={() => Navigate("/")}
            alt=""
          />
          <p>Задайте ваш вопрос</p>
          <a
            href="https://wa.me/79253338363"
            target="_blank"
            className="showLink"
          >
            {document.body.clientWidth > 640 && " В Whatsapp"}
            <img src={wt} alt="" />
          </a>
          <a
            href="https://t.me/internetvitrina"
            target="_blank"
            className="showLink"
          >
            {document.body.clientWidth > 640 && "  В Telegram"}
            <img src={tg} alt="" />
          </a>
          <div className="showbutton">Варианты ниш</div>
          <div className="showBusiness" onClick={() => setPops(true)}>
            Запустить бизнес онлайн<span class="flare"></span>
          </div>
          <div className="showContacs">
            <a href="">
              <span>+7</span> (925) 333 83-63
            </a>
            <p>с 9:00 до 21:00</p>
          </div>
        </div>
      </Fade>
      <h2>
        <Zoom left duration={1000}>
          <div>Выберите свою Версию</div>
        </Zoom>{" "}
        <Zoom left duration={700} cascade>
          интернет-витрина.рф
        </Zoom>
      </h2>
      <div className="sliderBody">
        <Fade left duration={700}>
          <div className="leftSliderBody">
            <p>
              <span>
                {" "}
                Решение под любую нишу бизнеса. Возможно тут уже есть ваша.{" "}
              </span>
              {document.body.clientWidth > 640 && <br />}
              Если нет, то мы можем её сделать быстро, обращайтесь.
            </p>
            <div className="sliderButtons">
              <img
                src={arrow}
                onClick={() =>
                  currentTheme != 0
                    ? setCurrentTheme(currentTheme - 1)
                    : setCurrentTheme(puk1[currentPrice].length - 1)
                }
                style={{ transform: "rotate(180deg)" }}
                alt=""
              />
              <p>Переключение слайдов</p>
              <img
                src={arrow}
                alt=""
                onClick={() =>
                  currentTheme != puk1[currentPrice].length - 1
                    ? setCurrentTheme(currentTheme + 1)
                    : setCurrentTheme(0)
                }
              />
            </div>
            <div className="slShow">
              <div className="slShowTrain">
                <div className="slShowEm">
                  <div>
                    {document.body.clientWidth < 640 && (
                      <img src={lp} className="keks" alt="" />
                    )}
                    <img
                      onClick={() => {
                        setSmb("");
                        setPop(true);
                      }}
                      className={`bigSnt ${
                        currentPrice == 4 ||
                        currentPrice == 5 ||
                        currentPrice == 3
                          ? "bigi"
                          : ""
                      }`}
                      ref={lft}
                      src={`/image/${arrPr[currentPrice]}/${arrN[currentTheme]}.webp`}
                      alt=""
                      id="sr"
                    />
                  </div>

                  {currentPrice != 4 &&
                    currentPrice != 5 &&
                    currentPrice != 3 && (
                      <div>
                        {document.body.clientWidth < 640 && (
                          <img src={lp} className="keks" alt="" />
                        )}
                        <img
                          onClick={() => {
                            setSmb("m");
                            setPop(true);
                          }}
                          className="smallSnt"
                          src={`/image/${arrPr[currentPrice]}/${arrN[currentTheme]}m.webp`}
                          alt=""
                          ref={rgt}
                        />
                      </div>
                    )}
                </div>
              </div>
              {document.body.clientWidth < 640 && (
                <div>
                  <h3>
                    Вы определились с выбором тарифа или хотите уточнить детали?
                  </h3>
                  <span className="uwa">Мы ответим на все ваши вопросы!</span>
                  <div className="snBtn" onClick={() => setPops(true)}>
                    Запустить бизнес онлайн<span class="flare"></span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fade>
        <Fade right duration={700}>
          {" "}
          <div className="rightSliderBody">
            <div className="below">
              <h4>Категории:</h4>
              <p>Выберете комфортную цену</p>
              <div className="currentShow" onClick={() => setCurrentPrice(0)}>
                <p
                  style={
                    currentPrice == 0
                      ? { background: "#0370F3", color: "#fff" }
                      : {}
                  }
                >
                  35.000 руб{" "}
                  <img
                    src={arr}
                    className={`${currentPrice == 0 && "avc"}`}
                    alt=""
                  />
                </p>
                <ul
                  style={
                    currentPrice == 0 && document.body.clientWidth > 640
                      ? { height: "16.5vw" }
                      : currentPrice == 0 && document.body.clientWidth < 640
                      ? { height: "70vw" }
                      : { height: 0 }
                  }
                >
                  <li
                    style={
                      currentTheme == 0
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(0)}
                  >
                    Ремонт и продажа техники
                  </li>
                  <li
                    style={
                      currentTheme == 1
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(1)}
                  >
                    Еда, напитки, доставка
                  </li>
                  <li
                    style={
                      currentTheme == 2
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(2)}
                  >
                    Салоны красоты
                  </li>
                  <li
                    style={
                      currentTheme == 3
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(3)}
                  >
                    Зоотовары и услуги
                  </li>
                  <li
                    style={
                      currentTheme == 4
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(4)}
                  >
                    Магазины одежды/обуви
                  </li>
                  <li
                    style={
                      currentTheme == 5
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(5)}
                  >
                    Бухгалтерские и юридические услуги
                  </li>
                  <li
                    style={
                      currentTheme == 6
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(6)}
                  >
                    Агентства недвижимости и застройщики
                  </li>
                </ul>
              </div>
              <div className="currentShow" onClick={() => setCurrentPrice(1)}>
                <p
                  style={
                    currentPrice == 1
                      ? { background: "#0370F3", color: "#fff" }
                      : {}
                  }
                >
                  75.000 руб{" "}
                  <img
                    src={arr}
                    className={`${currentPrice == 1 && "avc"}`}
                    alt=""
                  />
                </p>
                <ul
                  style={
                    currentPrice == 1 && document.body.clientWidth > 640
                      ? { height: "16.5vw" }
                      : currentPrice == 1 && document.body.clientWidth < 640
                      ? { height: "70vw" }
                      : { height: 0 }
                  }
                >
                  <li
                    style={
                      currentTheme == 0
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(0)}
                  >
                    Ремонт и продажа техники
                  </li>
                  <li
                    style={
                      currentTheme == 1
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(1)}
                  >
                    Еда, напитки, доставка
                  </li>
                  <li
                    style={
                      currentTheme == 2
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(2)}
                  >
                    Салоны красоты
                  </li>
                  <li
                    style={
                      currentTheme == 3
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(3)}
                  >
                    Зоотовары и услуги
                  </li>
                  <li
                    style={
                      currentTheme == 4
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(4)}
                  >
                    Магазины одежды/обуви
                  </li>
                  <li
                    style={
                      currentTheme == 5
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(5)}
                  >
                    Бухгалтерские и юридические услуги
                  </li>
                  <li
                    style={
                      currentTheme == 6
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(6)}
                  >
                    Агентства недвижимости и застройщики
                  </li>
                </ul>
              </div>
              <div className="currentShow" onClick={() => setCurrentPrice(2)}>
                <p
                  style={
                    currentPrice == 2
                      ? { background: "#0370F3", color: "#fff" }
                      : {}
                  }
                >
                  150.000 руб{" "}
                  <img
                    src={arr}
                    className={`${currentPrice == 2 && "avc"}`}
                    alt=""
                  />
                </p>
                <ul
                  style={
                    currentPrice == 2 && document.body.clientWidth > 640
                      ? { height: "34.5vw" }
                      : currentPrice == 2 && document.body.clientWidth < 640
                      ? { height: "182vw" }
                      : { height: 0 }
                  }
                >
                  <li
                    style={
                      currentTheme == 0
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(0)}
                    className="ogr"
                  >
                    Ремонт и продажа техники{" "}
                    <a
                      href="https://техника-витрина.рф/"
                      target="_blank"
                      className="linkVit"
                    >
                      {document.body.clientWidth > 640 && "Перейти"}
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1"
                          y="1.5"
                          width="22"
                          height="22"
                          rx="11"
                          stroke="#0370F3"
                          stroke-width="2"
                        />
                        <path
                          d="M10 8L15 13L10 18"
                          stroke="black"
                          stroke-width="2"
                        />
                      </svg>
                    </a>
                  </li>
                  <li
                    style={
                      currentTheme == 1
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(1)}
                    className="ogr"
                  >
                    Еда, напитки, доставка
                    <a
                      href="https://витрина-еда.рф"
                      target="_blank"
                      className="linkVit"
                    >
                      {document.body.clientWidth > 640 && "Перейти"}
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1"
                          y="1.5"
                          width="22"
                          height="22"
                          rx="11"
                          stroke="#0370F3"
                          stroke-width="2"
                        />
                        <path
                          d="M10 8L15 13L10 18"
                          stroke="black"
                          stroke-width="2"
                        />
                      </svg>
                    </a>
                  </li>
                  <li
                    style={
                      currentTheme == 2
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(2)}
                  >
                    Салоны красоты
                  </li>
                  <li
                    style={
                      currentTheme == 3
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(3)}
                  >
                    Зоотовары и услуги
                  </li>
                  <li
                    style={
                      currentTheme == 4
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(4)}
                  >
                    Магазины одежды/обуви
                  </li>
                  <li
                    style={
                      currentTheme == 5
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(5)}
                  >
                    Бухгалтерские и юридические услуги
                  </li>
                  <li
                    style={
                      currentTheme == 6
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(6)}
                  >
                    Агентства недвижимости и застройщики
                  </li>
                  <li
                    style={
                      currentTheme == 7
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(7)}
                  >
                    Фитнес клубы, спортивные секции
                  </li>
                  <li
                    style={
                      currentTheme == 8
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(8)}
                  >
                    Стоматологии, мед.центры
                  </li>
                  <li
                    style={
                      currentTheme == 9
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(9)}
                  >
                    Туризм и отели
                  </li>
                  <li
                    style={
                      currentTheme == 10
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(10)}
                  >
                    Школы офлайн и онлайн
                  </li>
                  <li
                    style={
                      currentTheme == 11
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(11)}
                    className="ogr"
                  >
                    Магазины ювелирных украшений
                    <a
                      href="https://веб-витрина.рф/"
                      target="_blank"
                      className="linkVit"
                    >
                      {document.body.clientWidth > 640 && "Перейти"}
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1"
                          y="1.5"
                          width="22"
                          height="22"
                          rx="11"
                          stroke="#0370F3"
                          stroke-width="2"
                        />
                        <path
                          d="M10 8L15 13L10 18"
                          stroke="black"
                          stroke-width="2"
                        />
                      </svg>
                    </a>
                  </li>
                  <li
                    style={
                      currentTheme == 12
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(12)}
                  >
                    Строительство и ремонт
                  </li>
                  <li
                    style={
                      currentTheme == 13
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(13)}
                  >
                    Автозапчасти и услуги
                  </li>
                  <li
                    style={
                      currentTheme == 14
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(14)}
                  >
                    Магазины цветов
                  </li>
                </ul>
              </div>
              <div className="currentShow" onClick={() => setCurrentPrice(3)}>
                <p
                  style={
                    currentPrice == 3
                      ? { background: "#0370F3", color: "#fff" }
                      : {}
                  }
                >
                  250.000 руб{" "}
                  <img
                    src={arr}
                    className={`${currentPrice == 3 && "avc"}`}
                    alt=""
                  />
                </p>
                <ul
                  style={
                    currentPrice == 3 && document.body.clientWidth > 640
                      ? { height: "3vw" }
                      : currentPrice == 3 && document.body.clientWidth < 640
                      ? { height: "15vw" }
                      : { height: 0 }
                  }
                >
                  <li
                    style={
                      currentTheme == 0
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(0)}
                  >
                    Агентства недвижимости и застройщики
                  </li>
                </ul>
              </div>
              <div className="currentShow" onClick={() => setCurrentPrice(4)}>
                <p
                  style={
                    currentPrice == 4
                      ? { background: "#0370F3", color: "#fff" }
                      : {}
                  }
                >
                  350.000 руб{" "}
                  <img
                    src={arr}
                    className={`${currentPrice == 4 && "avc"}`}
                    alt=""
                  />
                </p>
                <ul
                  style={
                    currentPrice == 4 && document.body.clientWidth > 640
                      ? { height: "3vw" }
                      : currentPrice == 4 && document.body.clientWidth < 640
                      ? { height: "15vw" }
                      : { height: 0 }
                  }
                >
                  <li
                    style={
                      currentTheme == 0
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(0)}
                  >
                    Агентства недвижимости и застройщики
                  </li>
                </ul>
              </div>
              <div className="currentShow" onClick={() => setCurrentPrice(5)}>
                <p
                  style={
                    currentPrice == 5
                      ? { background: "#0370F3", color: "#fff" }
                      : {}
                  }
                >
                  500.000 руб{" "}
                  <img
                    src={arr}
                    className={`${currentPrice == 5 && "avc"}`}
                    alt=""
                  />
                </p>
                <ul
                  style={
                    currentPrice == 5 && document.body.clientWidth > 640
                      ? { height: "4vw" }
                      : currentPrice == 5 && document.body.clientWidth < 640
                      ? { height: "15vw" }
                      : { height: 0 }
                  }
                >
                  <li
                    style={
                      currentTheme == 0
                        ? { fontWeight: "bold", color: "#0370F3" }
                        : {}
                    }
                    onClick={() => setCurrentTheme(0)}
                  >
                    Агентства недвижимости и застройщики
                  </li>
                </ul>
              </div>
            </div>
            {document.body.clientWidth > 640 && (
              <div>
                <h3>
                  Вы определились с выбором тарифа <br /> или хотите уточнить
                  детали?
                </h3>
                <span className="uwa">Мы ответим на все ваши вопросы!</span>
                <div className="snBtn" onClick={() => setPops(true)}>
                  Запустить бизнес онлайн<span class="flare"></span>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </div>
      <div className="showFooter">
        <div className="showFooterBody">
          <img src={logo} alt="" />
          <p>Контакты</p>
          <div className="addLink">
            <p>Телефон:</p>
            <a href="tel:+7 (925) 333 83-63">
              +7 (925) 333 83-63
              <img src={ph} alt="" />
            </a>
          </div>
          <div className="addLink">
            <p>Мессенджеры:</p>
            <div>
              <a href="https://t.me/internetvitrina" target="_blank">
                Telegramm <img src={tg} alt="" />
              </a>
              <a href="https://wa.me/79253338363" target="_blank">
                Whatsapp <img src={wt} alt="" />
              </a>
            </div>
          </div>
          <div className="addLink">
            <p>Время работы:</p>
            <a href="">
              с 9:00 до 21:00, пн-вс <img src={tim} alt="" />
            </a>
          </div>
        </div>
        <div className="footerLinks" style={{ display: "none" }}>
          <a href="">Политика конфиденциальности</a>
          <a href="">Согласие с рассылкой</a>
          <a href="">Отказ от ответственности</a>
          <span>*Предложение не является публичной офертой</span>
        </div>
      </div>
    </div>
  );
}
