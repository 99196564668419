import { Fade } from "react-reveal";
import "./style.css";
import logo from "../Landing/img/logo.svg";
import wt from "../Landing/img/wt.svg";
import tg from "../Landing/img/tg.svg";
import { useNavigate } from "react-router-dom";
export default function Thanks() {
  const Navigate = useNavigate();
  return (
    <div className="thanks">
      {" "}
      <Fade top>
        <div className="showSliderHeader">
          <img src={logo} className="showLogo" alt="" />
          <p>Задайте ваш вопрос</p>
          <a
            href="https://wa.me/79253338363"
            target="_blank"
            className="showLink"
          >
            {document.body.clientWidth > 640 && " В Whatsapp"}
            <img src={wt} alt="" />
          </a>
          <a
            href="https://t.me/internetvitrina"
            target="_blank"
            className="showLink"
          >
            {document.body.clientWidth > 640 && "  В Telegram"}
            <img src={tg} alt="" />
          </a>
          <div className="showbutton" onClick={() => Navigate("/ниши")}>
            Варианты ниш
          </div>

          <div className="showContacs">
            <a href="tel:+7(925) 333 83-63">
              <span>+7</span> (925) 333 83-63
            </a>
            <p>с 9:00 до 21:00</p>
          </div>
        </div>
      </Fade>
      <h3>Ваша заявка принята</h3>
      <p>
        В самое ближайшее время с вами свяжется наш специалист и даст подробные
        ответы на все вопросы
      </p>
      <div className="plank">
        <span>А пока подписывайтесь</span> на нашего Телеграм бота{" "}
        <span> и получите скидку до 40% </span>
        на услуги внутренней веб-студии и <span> ПОДАРОК</span>
      </div>
      <div
        className="showBusiness"
        onClick={() =>
          window.open("https://t.me/internetvitrina_bot", "_blank")
        }
      >
        Подписаться<span class="flare"></span>
      </div>
    </div>
  );
}
